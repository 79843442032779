@font-face{
  font-family: "Rubik-Light";
  font-display: swap;
  src: url('./assets/fonts/Rubik-Light.woff2');
}

@font-face{
  font-family: "Rubik-Regular";
  font-display: swap;
  src: url('./assets/fonts/Rubik-Regular.woff2');
}

@font-face{
  font-family: "Rubik-Medium";
  font-display: swap;
  src: url('./assets/fonts/Rubik-Medium.woff2');
}

@font-face {
  font-family: MuseoSans300;
  font-display: swap;
  src: url('./assets/fonts/museosans-300.woff2');
}

@font-face {
  font-family: MuseoSans500;
  font-display: swap;
  src: url('./assets/fonts/museo-sans-500.woff2');
}

@font-face {
  font-family: MuseoSans700;
  font-display: swap;
  src: url('./assets/fonts/museo-sans-700.otf');
}

html, body {
	width: 100%;
	height: 100%;
	font-family: 'Rubik-Regular';
	font-size: 17px;
	color: #3B4D70;
}

* {
	box-sizing: border-box;
}

#root, .App {
	width: 100%;
  height: 100%;
}

.App {
    padding-top: 40px;
}

.header {
	margin-bottom: 0px;
}


body {
  margin: 0;
  padding: 0;
}

.align-right {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

::placeholder {
	color: #fff
}


button {
	outline: none;
}

.inputGroup {
  margin-bottom: 40px;
  position: relative;
}

.inputGroup div{
	position: relative;
}

.inputGroup label {
  font-family: Rubik-Regular;
  font-weight: 400;
	margin-bottom: 10px;
	display: block;
	font-size: 14px;
	color: #696666;
  font-weight: 100;

}

.inputGroup input, .inputGroup select {
	padding: 16px;
  border-radius: 5px;
  border: 1px solid #D2D2D2;
  width: 100%;
  font-size: 16px;
  font-family: helvetica;
  color: #979797;
  font-weight: 100;
  height: 58px;
  background: #fff;
  -webkit-appearance: none;
}

.inputGroup .hasError input,
.inputGroup .hasError select,
.inputGroup .hasError textarea{
  border: solid 1px #e1464e;
}


.inputGroup span {
  color: red;
  background: #fff;
  display: block;
  border-radius: 6px;
  position: absolute;
  font-size: 14px;
  padding: 2px 1px;
  top: -30px;
  width: 100%;
  font-family: Rubik-Regular;
}

.secondary span {
  color: #fff;
}

.inputGroup.secondary span {
  color: #fff;
  background: none;
}

input::placeholder {
	color: #C8C8C8;
}

.layoutEmpty {
  height: 100%;
}

.fallback .logo {
  justify-content: center;
}

.PasswordResetLink {
  font-size: 13px;
  display: block;
  text-align: right;
  text-decoration: none;
  margin-top: 10px;
  font-weight: bold;
}
