@keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}
.container {
  width: 100%;
  height: 47px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.extendedNavigationItem {
  position: relative;
}
.extendedNavigationItem:hover {
  cursor: pointer;
}
.subNavigationContainer {
  position: absolute;
  top: 50px;
  width: 270px;
  height: 200px;
  border-radius: 6px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  list-style: none;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
}

.navLink .subLinks ul {
  padding: 0;
}

@media only screen and (min-width: 1170px) {
  .navLink .subLinks {
    display: none;
    position: absolute;
    margin-top: 0;
  }

  .navLink:hover .subLinks {
    display: flex;
    flex-direction: column;

  }

  .navLink .subLinks ul {
    padding: 0;
    width: 400px;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 2px 30px 0 rgba(0,0,0,.09);

  }
}


.subLinks {
  left: 50;
  padding: 0;
  margin-top: 40px;

}

.subLinks ul {
  margin-top: 40px;
}


.is-active .subNavigationContainer {
  position: unset;
  background-color: transparent;
  margin-top: 40px;
  box-shadow: none;
}
.subNavItem {
  display: flex;
  align-items: center;
  margin: 0 !important;
  height: 60px;
  padding-left: 15px;
}
.is-active .subNavItem {
  padding: 0;
}
.subNavItem a {
  padding: 0 !important;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.1px;
  color: #444444;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}
.subNavItem a.active {
  border: none !important;
}
.subNavItem a:hover{
  border: none !important;
  background-color: transparent;
}
.subNavItem:hover{
  background-color: rgba(255, 132, 0, 0.05);
}
.is-active .subNavItem:hover{
  background-color: transparent;
}
.subNavItem img{
  width: 25px;
  height: 25px;
}
.logo {
  text-align: left;
  margin-bottom: 80px !important;
}
.logo img {
  width: 200px;
}
.is-active .loginLink a {
  color: #fff !important;
  font-size: 16px !important;
}
.loginLink a::after {
  margin-left: 5px;
  content: url("../assets/images/ion-arrow-right.svg");
}
.is-active .loginLink a::after {
  content: none;
}
.special a {
  padding: 13px !important;
  border: 2px solid #ff8400;
  color: #ff8400 !important;
  background: white;
}
.is-active .special a {
  background: transparent;
}
.social-icons-container {
  width: 200px;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.social-icons-container a:hover {
  border: none !important;
}
.is-active li {
  position: relative;
}
/*.is-active li:not(.logo):not(.loginLink):not(.social-icons-container):after {*/
  /*content: '';*/
  /*width: 15px;*/
  /*bottom: 0;*/
  /*left: calc(50% - 10px);*/
  /*position: absolute;*/
  /*height: 0;*/
  /*border: 1px solid #ffffff;*/
/*}*/
.nav {
	padding: 15px;
	list-style-type: none;
	top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(29, 65, 136, 0.9);
  margin: 0;
  padding-top: 40px;
  display: none;
  transition: all 1s;
  opacity: 0;
  position: fixed;
  z-index: 3;
}
.nav.is-active {
  overflow-y: scroll;
}
.nav .extendedNavigationItem {
  padding-bottom: 0 !important;
  margin-top: 3px;
}

.nav .extendedNavigationItem:hover .subNavigationContainer {
  display: flex;
}

.nav .extendedNavigationItem span {
  padding-bottom: 5px;
  height: 22px;
}
.nav a, .nav .extendedNavigationItem {
	text-transform: capitalize;
  font-size: 16px;
  font-family: 'MuseoSans300';
  font-weight: 100;
  letter-spacing: 1px;
  text-decoration: none;
  color: #444444;
  padding-bottom: 5px;
}
.nav a:hover, .nav .extendedNavigationItem span:hover {
  border-bottom: 2px solid #ff8400;
}
.is-active a, .is-active .extendedNavigationItem {
  color: #fff;
}

.nav a.active, .nav .extendedNavigationItem.active span {
  border-bottom: 2px solid #ff8400;
}

.nav li {
	/*margin-bottom: 40px;*/
}
.is-active li {
  padding-bottom: 25px;
  margin-bottom: 25px;
}
.burgerWrap {
    z-index: 4;
    transform: scale(1);
}

.burgerWrap button {
	border: none;
	outline: none;
}

.copy {
  display: inline-block;
  margin-left: 20px;
}

.is-active {
  display: block;
  text-align: left;
	/*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
	opacity: 1;
  -webkit-animation: fadeInFromNone 0.2s ease-out;
  -moz-animation: fadeInFromNone 0.2s ease-out;
  -o-animation: fadeInFromNone 0.2s ease-out;
  animation: fadeInFromNone 0.2s ease-out;
}

   /*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  top: -5px;
  position: relative;
}


.hamburger-box {
  width: 25px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  zoom: 0.7;
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #ff8400;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner.whiteLink, .hamburger-inner.whiteLink::before, .hamburger-inner.whiteLink::after {
  background-color: #ffffff;
}
.is-active .hamburger-inner,
.is-active .hamburger-inner::before,
.is-active .hamburger-inner::after {
  background-color: #fff;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -10px; }
.hamburger-inner::after {
  bottom: -10px;
}
/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

@media only screen and (min-width: 1240px) {
  .nav.allWhite li a {
    color: white;
  }
  .nav.allWhite .subLinks a {
    color: #444444;
  }
  .nav.allWhite .special a {
    color: white !important;
    border: 2px solid white;
    background: transparent;
  }
	.burgerWrap {
		display: none;
	}
  .social-icons-container {
    display: none;
  }
  .logo {
    display: none;
  }
	.nav a, .nav .extendedNavigationItem {
		font-size: 14px;
	}

	.nav {
		display: flex;
    align-items: center;
		position: relative;
		background: none;
		padding: 0;
		width: auto;
    height: auto;
    opacity: 1;
	}

	.nav li {
		float: left;
		margin-right: 73px;
	}

	.nav li:last-child {
		margin-right: 0;
	}

}
